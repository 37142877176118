.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* src/App.css */

body {
  font-family: 'Arial', sans-serif;
  background-color: #eff7f8; /* 清淡自然的背景颜色 */
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  color: #424242; /* 深灰色文字 */
}

.container {
  width: 100%;
  max-width: 800px;
  background: white;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  margin: 20px;
  
}

.input-field {
  width: 40%;
  padding: 12px 15px;
  margin-bottom: 10px;
  border: 2px solid #d3d3d3;
  border-radius: 4px;
  box-sizing: border-box;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.05);
}

.message-submit {
  padding: 10px 20px;
  background-color: #5caed4;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.message-submit:hover {
  background-color: #17cfc5;
}

.message-list {
  list-style: none;
  padding: 0;
  /* max-height: 300px; */
  overflow-y: auto;
}

.message-item {
  background-color: #cff3cb;
  margin-top: 10px;
  padding: 10px;
  border-radius: 4px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
}

.message-content {
  font-size: 1rem;
}

.message-time {
  font-size: 0.8rem;
  color: #707070;
  margin-top: 5px;
}


body, html {
  height: 100%;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

body {
  background: rgb(22, 18, 42);
  overflow: hidden;
}

.stars {
  width: 100%;
  height: 100%;
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}

.star {
  width: 1px;
  height: 1px;
  background: transparent;
  border-radius: 50%;
  position: absolute;
  bottom: 50%;
  animation: twinkle 5s infinite ease-in-out;
}

@keyframes twinkle {
  0%, 100% {
    transform: scale(1);
    background: #fff;
  }
  50% {
    transform: scale(1.5);
    background: rgb(187, 96, 187);
  }
}

/* 星空背景样式 */
.stars, .shooting-stars {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.star {
  background: white;
  border-radius: 50%;
  position: absolute;
  width: 2px;
  height: 2px;
  opacity: 0.8;
}

.shooting-star {
  background: white;
  border-radius: 50%;
  position: absolute;
  width: 2px;
  height: 2px;
  animation: shootingStar 2s linear infinite;
}

/* 流星动画 */
@keyframes shootingStar {
  0% {
    transform: translateX(0px) translateY(0px);
    opacity: 1;
  }
  100% {
    transform: translateX(300px) translateY(300px);
    opacity: 0;
  }
}

/* 调整容器样式 */
.container {
  position: absolute;
  max-width: 900px;
  max-height: 1200px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  
  background-color: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  z-index: 1;

  width: 92%;
  height: 75vh;
  /* background: white; */
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
  overflow: auto; /* 如果内容超过容器高度，则启用滚动 */
}