body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

nav {
  position: fixed; /* 固定定位 */
  top: 0; /* 顶部对齐 */
  left: 0; /* 左侧对齐 */
  width: 100%; /* 导航栏宽度占满整个屏幕宽 */
  background-color: #333; /* 背景颜色 */
  color: white;
  padding: 10px 0; /* 增加一些内边距 */
  text-align: center;
  z-index: 1000; /* 确保导航栏位于其他内容的上方 */
}


nav a {
  color: white;
  padding: 10px;
  text-decoration: none;
}

/* 当鼠标悬停在链接上时 */
nav a:hover {
  background-color: #ddd;
  color: black;
}
